import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import tw, { css, theme } from 'twin.macro'
import ArticleGrid from '../../components/article-grid'
import Banner from '../../components/banner'
import FeaturedArticle from '../../components/featured-article'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import SubNav from '../../components/sub-nav'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'

const OPTIONS = { threshold: [0.25, 0.75] }

const NewsPage = ({ data: { news } }) => {
  const { ref: featuredRef, entry: featuredEntry } = useInView(OPTIONS)
  const { ref: newsRef, entry: newsEntry } = useInView(OPTIONS)
  const page = useMemo(() => flatten(news || {}, ['hero', 'featured', 'banner']), [news])
  const anchorLinks = useMemo(
    () => [
      {
        label: get(page, 'featuredLabel'),
        link: '#featured',
        active:
          featuredEntry &&
          newsEntry &&
          featuredEntry.intersectionRatio >= newsEntry.intersectionRatio,
      },
      {
        label: get(page, 'newsLabel'),
        link: '#news',
        active:
          featuredEntry &&
          newsEntry &&
          newsEntry.intersectionRatio > featuredEntry.intersectionRatio,
      },
    ],
    [featuredEntry, newsEntry, page]
  )

  const currentPath = useMemo(
    () =>
      featuredEntry && newsEntry && newsEntry.intersectionRatio > featuredEntry.intersectionRatio
        ? '#entry'
        : '#featured',
    [featuredEntry, newsEntry]
  )

  const articles = useMemo(
    () =>
      (get(page, 'news') || []).map((release) => ({
        date: release.date,
        headline: release.headline,
        excerpt: get(release, 'contentNode.childMarkdownRemark.excerpt'),
        callToAction: {
          label: get(page, 'newsButtonLabel'),
          link: `/news/${release.slug}`,
        },
      })),
    [page]
  )

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
      headerTransition={true}
      headerTransitionColor="white"
    >
      <section id="hero" css={tw`relative bg-secondary-500`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          descriptionHeadingType="h3"
          style={css`
            h3 {
              ${tw`font-light`}
            }
          }`}
          extended
        />
      </section>
      <SubNav
        links={anchorLinks}
        style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-mobile-header lg:top-desktop-header`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
        `}
        selectBackground={theme`colors.white`}
        currentPath={currentPath}
      />
      <section id="featured" css={tw`bg-secondary-500 bg-opacity-5`} ref={featuredRef}>
        <div css={global`layout.container`}>
          <FeaturedArticle
            date={get(page, 'featured.date')}
            headline={get(page, 'featured.headline')}
            excerpt={get(page, 'featured.contentNode.childMarkdownRemark.excerpt')}
            callToAction={{
              label: get(page, 'featuredButtonLabel'),
              link: `/news/${get(page, 'featured.slug')}`,
            }}
            image={get(page, 'featured.image')}
          />
        </div>
      </section>
      <section id="news" ref={newsRef} css={tw`py-20 lg:py-32`}>
        <ArticleGrid articles={articles} title={get(page, 'newsLabel')} />
      </section>
      <section id="call-to-action" css={[global`layout.container`, tw`pb-32`]}>
        <Banner
          title={get(page, 'banner.titleNode')}
          subtitle={get(page, 'banner.subtitleNode')}
          description={get(page, 'banner.descriptionNode')}
          callToAction={get(page, 'banner.callToAction')}
        />
      </section>
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    news: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default NewsPage

export const query = graphql`
  query NewsQuery {
    news: datoCmsNews {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredLabel
      featuredButtonLabel
      featured {
        typeOfArticle
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      newsLabel
      newsButtonLabel
      news {
        typeOfArticle
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      banner {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
    }
  }
`
